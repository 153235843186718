import clsx from 'clsx';
import {Dispatch, SetStateAction} from 'react';
import {TableHeaderColumnTooltip} from '../../helpers/components/table';
import * as styles from '../../pages/users/index.module.css';
import ButtonGroup from '../../components/core/button/buttonGroup';
import ButtonIcon from '../../components/core/button/buttonIcon';
import {EditIcon} from '../../components/core/icons';
import {ColumnSortingSettings} from '../../interfaces/table';
import {sortingSettings} from '../../helpers/tables';
import {UserOrderBy} from '../../graphql-types';

interface ITableUserManagerColumnsProps {
    setModal: Dispatch<SetStateAction<string | false>>,
    setRowData: Dispatch<SetStateAction<string>>,
    sorting: ColumnSortingSettings<UserOrderBy>,
}

// eslint-disable-next-line import/prefer-default-export
export const tableUserManagerColumns = ({setModal, setRowData, sorting}: ITableUserManagerColumnsProps) => ([
    {
        Header: () => TableHeaderColumnTooltip({
            text: 'Name',
            sortingSettings: sortingSettings(sorting, UserOrderBy.Name),
        }),
        hasWidthAuto: true,
        Cell: ({row}: {row: any}) => (<strong>{row.original.name}</strong>),
        accessor: 'name',
    },
    {
        Header: () => TableHeaderColumnTooltip({text: 'Languages'}),
        Cell: ({row}: {row: any}) => row.original.accessibleLanguages.map((lang: {name: string}) => lang.name).join(', '),
        id: 'languages',
    },
    {
        Header: () => TableHeaderColumnTooltip({text: 'Team'}),
        accessor: 'team.name',
        hasWidthAuto: true,
        Cell: ({row = {}}: {row: any}) => {
            const isGlobal = ['global', 'region'].includes(row.original.team.level);

            return (
                <span className={clsx(isGlobal && styles.teamBold)}>
                    {row.original.team.name}
                </span>
            );
        },
    },
    {
        Header: '',
        id: 'actions',
        disableSortBy: true,
        hasWidthAuto: true,
        hasBorder: true,
        Cell: ({row = {}}: {row: any}) => (
            <ButtonGroup>
                <ButtonIcon
                    tooltipText="Edit user"
                    icon={EditIcon}
                    onClick={() => {
                        setModal('edit');
                        setRowData(row.original.id);
                    }}
                />
            </ButtonGroup>
        ),
    },
]);
