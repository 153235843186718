import {UserIcon} from '../../../components/core/icons';

// eslint-disable-next-line import/prefer-default-export
export const tabsData = [
    {
        id: 'user-manager',
        title: 'User manager',
        icon: UserIcon,
        to: '/users',
    },
];
