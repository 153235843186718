import {useQuery, useLazyQuery} from '@apollo/client';
import {
    useEffect,
    useMemo,
    useState,
    useCallback,
} from 'react';
import store from 'store/dist/store.modern';
import {PageProps} from 'gatsby';
import {ColumnSortingActive} from '../../interfaces/table';
import {Filter} from '../../interfaces/filters';
import {
    GetMyTeamDocument,
    GetTeamsDocument,
    GetUserFiltersDocument,
    GetUserFiltersQuery,
    GetUsersDocument,
    OrderWay,
    UserOrderBy,
} from '../../graphql-types';
import {AddUserModal, EditUserModal} from '../../modals/users';
import {ActionButtons, Layout} from '../../components/layout';
import {Button, Tabs} from '../../components/core';
import Table from '../../components/core/table/table';
import FullFilter from '../../components/modules/fullFilter/fullFilter';
import {tableUserManagerColumns} from '../../configs/pages/users';
import {AddIcon} from '../../components/core/icons';
import TableFooter from '../../components/core/table/tableFooter';
import useFilter from '../../hooks/useFilter';
import useLocalStorage from '../../hooks/useLocalStorage';
import getFilterAttributes from '../../helpers/filters/getFilterAttributes';
import toastify, {ToastLocationState} from '../../helpers/toast/toastify';
import {tabsData} from '../../configs/pages/users/tabs';

type UsersPageProps = PageProps<null, null, ToastLocationState>;

const UsersPage = ({location}: UsersPageProps) => {
    const [paging, setPaging] = useState({
        limit: store.get('userSettings')?.showPerPage || 10,
        page: 1,
    });
    const {data: teamsData} = useQuery(GetTeamsDocument);
    const [rowDataId, setRowData] = useState('');
    const [modal, setModal] = useState<string | false>(false);
    const [localStorageFilters, setLocalStorageFilters] = useLocalStorage<Filter | null>('filterUsersTable');
    const [searchQuery, setSearchQuery] = useState('');
    const {data: userFiltersData} = useQuery(GetUserFiltersDocument);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [activeSorting, setActiveSorting] = useState<ColumnSortingActive<UserOrderBy>>({});
    const tableColumns = useMemo(() => tableUserManagerColumns({
        setModal,
        setRowData,
        sorting: {
            enabled: [UserOrderBy.Name],
            active: activeSorting,
            setSorting: setActiveSorting,
        },
    }), [activeSorting]);
    const {data: myData} = useQuery(GetMyTeamDocument);
    const isBu = myData && myData.me.team.level === 'bu';
    const {
        filters,
        loaded: filtersLoaded,
        toggleFilter,
        setFilter,
        setAllFilters,
    } = useFilter(localStorageFilters || undefined);
    const [
        fetchUsers, {
            data,
            error,
            loading,
        },
    ] = useLazyQuery(GetUsersDocument, {
        fetchPolicy: 'cache-and-network',
    });

    const [userData] = data
        ? data.users.results.filter((user: {id: string}) => user.id === rowDataId)
        : [{}];

    const getQueryVariables = (resetPaging?: boolean) => ({
        paging: resetPaging ? {limit: paging.limit, page: 1} : paging,
        filter: {
            fulltext: searchQuery,
            teamIds: filters.teams as string[],
        },
        order: {
            by: activeSorting.column,
            way: activeSorting.desc ? OrderWay.Desc : OrderWay.Asc,
        },
    });

    const userFilters = useMemo(() => {
        if (!userFiltersData) {
            return {};
        }

        return getFilterAttributes<GetUserFiltersQuery>(userFiltersData);
    }, [userFiltersData]);

    const handleApplyFilters = (): void => {
        setLocalStorageFilters(filters);

        fetchUsers({variables: getQueryVariables(true)});
    };

    useEffect(() => {
        if (!filtersLoaded) {
            return;
        }

        fetchUsers({variables: getQueryVariables(true)});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtersLoaded]);

    useEffect(() => {
        fetchUsers({variables: getQueryVariables(true)});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        searchQuery,
        activeSorting.column,
        activeSorting.desc,
    ]);

    useEffect(() => {
        fetchUsers({variables: getQueryVariables()});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paging]);

    useEffect(() => {
        if (!location?.state?.toast) {
            return;
        }

        toastify(location.state.toast);
        window.history.replaceState(null, '');
        fetchUsers({variables: getQueryVariables()});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.state?.toast]);

    return (
        <>
            <Layout
                title="User manager"
                scope="users"
            >
                <Tabs data={tabsData} />
                <ActionButtons buttonsHidden={filtersOpen}>
                    <Button
                        onClick={() => setModal('add')}
                        text="Create a new user"
                        icon={AddIcon}
                    />
                </ActionButtons>
                <FullFilter
                    data={userFilters}
                    filters={filters}
                    filterMethods={{
                        toggleFilter,
                        setAllFilters,
                        applyFilters: handleApplyFilters,
                        setFilter,
                    }}
                    tagsOnly={true}
                    search={{
                        query: searchQuery,
                        applyQuery: setSearchQuery,
                    }}
                    onOpenStateChange={setFiltersOpen}
                    render={() => (
                        <>
                            <Table
                                settings={{
                                    itemsName: 'users',
                                    isStriped: true,
                                }}
                                columns={tableColumns}
                                data={data?.users.results || []}
                                dataLoading={{
                                    active: loading,
                                    text: 'Fetching your data, please wait...',
                                }}
                                dataError={{
                                    active: !!error,
                                    text: `There was an error: ${error?.message || 'network error'}`,
                                }}
                            />
                            {data && (
                                <TableFooter
                                    rows={data.users.paging.count}
                                    paging={{
                                        limit: data.users.paging.limit,
                                        page: data.users.paging.page,
                                    }}
                                    setPaging={setPaging}
                                    itemsName="users"
                                />
                            )}
                        </>
                    )}
                />
            </Layout>
            {modal && modal === 'add' && (
                <AddUserModal
                    setModal={setModal}
                    teamsData={teamsData?.teams}
                    isBu={isBu as boolean}
                    languages={myData?.me.team.accessibleLanguages as {id: string; name: string}[]}
                />
            )}
            {modal && modal === 'edit' && (
                <EditUserModal
                    setModal={setModal}
                    rowDataId={rowDataId}
                    userData={userData}
                    teamsData={teamsData?.teams}
                    isBu={isBu as boolean}
                />
            )}
        </>

    );
};

export default UsersPage;
