import {
    Control,
    Controller,
    FieldValues,
    useFieldArray,
    ArrayPath,
} from 'react-hook-form';
import Checkbox from './checkbox';
import * as styles from './checkboxGroup.module.css';

interface ICheckboxGroupProps<T extends FieldValues> {
    title: string,
    group: ArrayPath<T>,
    control: Control<FieldValues>,
    colCount?: number,
}

const CheckboxGroup = <T extends Record<string, any>>({
    title,
    group,
    control,
    colCount = 6,
}: ICheckboxGroupProps<T>) => {
    const {fields}: {fields: any[]} = useFieldArray({
        control,
        name: group,
    });

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                {title}
            </div>
            <div
                className={styles.group}
                style={{
                    '--columnCount': colCount,
                } as React.CSSProperties}
            >
                {fields && fields.map((field, index) => (
                    <Controller
                        key={`${group}_${field.value}`}
                        render={({
                            field: {
                                onChange,
                                value,
                                name,
                            },
                        }) => (
                            <Checkbox
                                label={field.label}
                                name={name}
                                checked={value.checked}
                                handleChange={newValue => {
                                    onChange({
                                        value: newValue,
                                        id: newValue,
                                        label: field.label,
                                        checked: value.checked !== true,
                                    });
                                }}
                                className={styles.groupedCheckbox}
                                value={field.value}
                            />
                        )}
                        control={control}
                        name={`${group}[${index}]`}
                    />
                ))}
            </div>
        </div>
    );
};

export default CheckboxGroup;
