const unflattenObject = (object: Record<string, any>) => {
    const result = {} as Record<string, any>;
    let temp: Record<string, any>;
    let substrings: string[];
    let i;

    Object.keys(object).forEach(key => {
        substrings = key.split('.');
        temp = result;

        for (i = 0; i < substrings.length - 1; i++) {
            if (!(substrings[i] in temp)) {
                if (Number.isFinite(substrings[i + 1])) {
                    temp[substrings[i]] = [];
                } else {
                    temp[substrings[i]] = {};
                }
            }

            temp = temp[substrings[i]];
        }

        temp[substrings[substrings.length - 1]] = object[key];
    });

    return result;
};

const flattenObjectKeys = (object: Record<string, any>, currentKey?: string): string[] => {
    let result: string[] = [];

    Object.keys(object).forEach(key => {
        const tempKey = currentKey ? `${currentKey}.${key}` : key;

        if (typeof object[key] !== 'object' || Array.isArray(object[key])) {
            result.push(tempKey);
        } else {
            result = [...result, ...flattenObjectKeys(object[key], tempKey)];
        }
    });

    return result;
};

const getValueFromDeepObject = (dataObject: Record<string, any>, path: string) => path.split('.').reduce((r, k) => r?.[k], dataObject);

const pickDirtyFields = (newData: Record<string, any>, dirtyFields: Record<string, any>) => {
    const flattendDirtyKeys = flattenObjectKeys(dirtyFields);

    const res: Record<string, any> = {};

    flattendDirtyKeys.forEach(key => {
        const keyValue = getValueFromDeepObject(newData, key);

        if (keyValue !== undefined) {
            res[key] = keyValue;
        }
    });

    return unflattenObject(res);
};

export const convertToBoolean = (value: 'true' | 'false') => value === 'true';

export default pickDirtyFields;
