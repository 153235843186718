/**
 * Validate email address
 * @param email - email address
 */
const validateEmail = (email: string): string | boolean => {
    const sanitizedEmail = email.toLowerCase();

    if (
        sanitizedEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(sanitizedEmail)
    ) {
        return 'Invalid e-mail address';
    }

    return true;
};

export default validateEmail;
